<template>
  <div class="sandpiperbackground">
    <section class="section container" v-if="num_terrabases!==null" >
      <h1 class="title">{{ num_runs.toLocaleString("en-US") }} metagenomes screened</h1>
      <h2 class="subtitle">{{ num_terrabases.toLocaleString("en-US") }} Tbp and {{ num_bioprojects.toLocaleString("en-US") }} projects</h2>

      <p>Sandpiper {{ version }} is a resource for interrogating public shotgun metagenome datasets,
      presenting the results generated by <a href="https://github.com/wwood/singlem">SingleM</a>.</p>

      <p>We gratefully acknowledge the large collective effort expended
      in administering, gathering, sequencing and uploading these datasets into
      the public domain.</p>

      <br /><p>Get started exploring metagenomes with <router-link to="/Search">search</router-link>.</p>
    </section>

    <section class="section container is-large" />
  </div>
</template>

<script>
import { fetchSandpiperStats } from '@/api'

export default {
  name: 'Home',
  title: 'Sandpiper',
  data: function () {
    return {
      num_terrabases: null,
      num_runs: null,
      num_bioprojects: null,
      version: null
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  methods: {
    fetchData () {
      fetchSandpiperStats()
        .then(response => {
          const r = response.data
          this.num_terrabases = r.num_terrabases
          this.num_runs = r.num_runs
          this.num_bioprojects = r.num_bioprojects
          this.version = r.version
        })
    }
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  }
}
</script>
